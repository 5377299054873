import {FlexPage} from '@hconnect/common/components/FlexPage'
import {sideCardSize, tableWithOutSideCard, tableWithSideCard} from '@hconnect/common/consts'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Grid, useMediaQuery, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {ChecklistTemplateContainer} from '../containers/ChecklistTemplateContainer'
import {ChecklistTemplatesContainer} from '../containers/ChecklistTemplatesContainer'
import {ChecklistTemplatesListContainer} from '../containers/ChecklistTemplatesListContainer'
import {
  useChecklistTemplatesPageSearchParameters,
  SearchParamsMode
} from '../hooks/useChecklistTemplatesQueryParameter'
import {ChecklistTemplate, SortOrder} from '../types'

type PathParameter = {
  plantId?: string
}

const DEFAULT_PAGE = 0
const DEFAULT_ROWS_PER_PAGE = 10

export const ChecklistsTemplatesPage = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const [pageSearch, setPageSearch] = useChecklistTemplatesPageSearchParameters()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isTemplateDetailsMode = pageSearch.mode === SearchParamsMode.TEMPLATE_DETAILS
  const selectedTemplateId = isTemplateDetailsMode ? pageSearch.templateId : undefined
  const isTemplateDetailsDisplayed = isTemplateDetailsMode && selectedTemplateId

  const handleTemplateClick = (template: ChecklistTemplate) => {
    setPageSearch({mode: SearchParamsMode.TEMPLATE_DETAILS, templateId: template.id})
  }

  const handleSortChecklistsChange = (_columnKey: string, sortOrder: SortOrder) => {
    if (isTemplateDetailsMode) {
      setPageSearch({
        ...pageSearch,
        sortOrder
      })
    }
  }

  const handlePaginationChange = (page: number, rowsPerPage: number) => {
    if (isTemplateDetailsMode) {
      setPageSearch({
        ...pageSearch,
        page,
        rowsPerPage
      })
    }
  }

  const templateDetailsComponent = isTemplateDetailsDisplayed ? (
    <ChecklistTemplateContainer
      plantId={plantId}
      templateId={selectedTemplateId}
      appName="HProduce"
      onClose={() => setPageSearch({mode: SearchParamsMode.TABLE_ONLY})}
      page={pageSearch.page ?? DEFAULT_PAGE}
      rowsPerPage={pageSearch.rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
      onPaginationChange={handlePaginationChange}
      sortOrder={pageSearch.sortOrder ?? SortOrder.DESCENDING}
      onSortChange={handleSortChecklistsChange}
    />
  ) : null

  return (
    <FlexPage title={t(`${checklistsPrefix}.navPages.checklists`)}>
      {isMobile ? (
        <MobileLayout
          checklistTemplates={
            <ChecklistTemplatesListContainer
              plantId={plantId}
              selectedTemplateId={selectedTemplateId}
              onTemplateClick={handleTemplateClick}
            />
          }
          templateDetails={templateDetailsComponent}
        />
      ) : (
        <DesktopLayout
          checklistTemplates={
            <ChecklistTemplatesContainer
              plantId={plantId}
              selectedTemplateId={selectedTemplateId}
              onTemplateClick={handleTemplateClick}
            />
          }
          templateDetails={templateDetailsComponent}
        />
      )}
    </FlexPage>
  )
}

type LayoutProps = {
  checklistTemplates: React.ReactNode
  templateDetails?: React.ReactNode
}

const DesktopLayout = ({checklistTemplates, templateDetails}: LayoutProps) => {
  const isTemplatePreviewDisplayed = !!templateDetails

  return (
    <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
      <Grid
        item
        height={'100%'}
        {...(isTemplatePreviewDisplayed ? {...tableWithSideCard, sm: 6} : tableWithOutSideCard)}
      >
        <CardBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {checklistTemplates}
        </CardBox>
      </Grid>
      {isTemplatePreviewDisplayed && (
        <Grid
          item
          {...sideCardSize}
          sm={6}
          sx={{
            height: '100%',
            overflow: 'hidden'
          }}
        >
          {templateDetails}
        </Grid>
      )}
    </Grid>
  )
}

const MobileLayout = ({checklistTemplates, templateDetails}: LayoutProps) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'))
  const isTemplatePreviewDisplayed = !!templateDetails
  const isListHidden = isPhone && isTemplatePreviewDisplayed

  return (
    <Grid container spacing={2}>
      {!isListHidden && (
        <Grid
          item
          {...(isTemplatePreviewDisplayed ? {...tableWithSideCard, sm: 6} : tableWithOutSideCard)}
        >
          {checklistTemplates}
        </Grid>
      )}
      {isTemplatePreviewDisplayed && (
        <Grid item {...sideCardSize} sm={6}>
          {templateDetails}
        </Grid>
      )}
    </Grid>
  )
}
