import {dateFormatter} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Grid, Stack, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {eventRowSx} from '../../myTasks/components/styles'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {ChecklistTemplate} from '../types'

type Props = {
  data?: ChecklistTemplate[]
  selectedTemplateId?: string
  onTemplateClick: (template: ChecklistTemplate) => void
}

export const ChecklistTemplatesList = ({data, selectedTemplateId, onTemplateClick}: Props) => {
  return (
    <Stack spacing={2}>
      {data?.map((checklist) => (
        <ChecklistTemplateListItem
          key={checklist.id}
          item={checklist}
          selected={selectedTemplateId === checklist.id}
          onClick={() => onTemplateClick(checklist)}
        />
      ))}
    </Stack>
  )
}

const ChecklistTemplateListItem = ({
  item,
  selected,
  onClick
}: {
  item: ChecklistTemplate
  selected: boolean
  onClick?: () => void
}) => {
  const {checklistsPrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  return (
    <Box sx={{bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1}} onClick={onClick}>
      <CardBox sx={eventRowSx(selected)}>
        <Stack spacing={1.5}>
          <Typography variant="h4">{item.name}</Typography>
          <Typography>{item.description}</Typography>
          <Grid container columns={{xs: 2, md: 4}} rowSpacing={1.5}>
            <GridDataCell
              label={t(`${checklistsPrefix}.label.type`)}
              value={t(`${checklistsPrefix}.checklist.templateType.${item.type}`)}
            />
            <GridDataCell
              label={t(`${checklistsPrefix}.label.category`)}
              value={t(`${checklistsPrefix}.checklist.templateCategory.${item.category}`)}
            />
            <GridDataCell
              label={t(`${checklistsPrefix}.label.addedToTheSystem`)}
              value={dateFormatter(moment(item.createdAt))}
            />
          </Grid>
        </Stack>
      </CardBox>
    </Box>
  )
}

const GridDataCell = ({label, value}: {label: string; value: string}) => (
  <Grid item xs={1} sm={1} md={1}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Typography>{value}</Typography>
  </Grid>
)
