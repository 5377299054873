import {Permission} from '@hconnect/apiclient'
import {Route} from 'react-router-dom'

import {PerformancePlantSelect} from '../performanceDashboard/pages/PerformancePlantSelect'
import {checkFeatureFlag} from '../shared/helpers/featureFlagHelpers'
import {PermissionsContextProvider} from '../shared/hooks/usePermission'
import {TranslationPrefixProvider} from '../shared/hooks/useTranslationPrefix'
import {Context} from '../shared/types/temp.types'

import {UserContextProvider} from './hooks/useUserContext'
import {ChecklistsTemplatesPage, PlantChecklistPage, ReliabilityEngineerDashboard} from './pages'
import {
  CHECKLISTS,
  PLANT_CHECKLISTS_TEMPLATES,
  PLANT_CHECKLIST,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD
} from './routing/routes'
import {DefaultShell} from './templates'
import {
  hasChecklistsPermission,
  hasViewReliabilityEngineerDashboardPermission
} from './utils/permissions'

const Wrapper: React.FC<{
  context: Context
  permissions: Permission[]
  children: React.ReactNode
}> = ({children, context, permissions}) => (
  <TranslationPrefixProvider>
    <UserContextProvider initialState={context.user}>
      <PermissionsContextProvider initialState={permissions}>
        <DefaultShell context={context}>{children}</DefaultShell>
      </PermissionsContextProvider>
    </UserContextProvider>
  </TranslationPrefixProvider>
)

// TODO: HCP-78794 change to getHrocRoutes when refactoring checklists to hroc namespace
export const getChecklistsRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  const areChecklistsEnabled = checkFeatureFlag('checklists')

  return (
    <>
      {areChecklistsEnabled && hasChecklistsPermission(permissions) && (
        <>
          <Route
            key="checklists-plant-select"
            path={CHECKLISTS}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PerformancePlantSelect path={PLANT_CHECKLISTS_TEMPLATES} />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLISTS_TEMPLATES}
            key="checklists-templates"
            element={
              <Wrapper context={context} permissions={permissions}>
                <ChecklistsTemplatesPage />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLIST}
            key="plant-checklist"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantChecklistPage />
              </Wrapper>
            }
          />
        </>
      )}
      {hasViewReliabilityEngineerDashboardPermission(permissions) && (
        <>
          <Route
            key="reliability-engineer-dashboard-plant-select"
            path={RELIABILITY_ENGINEER_DASHBOARD}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PerformancePlantSelect path={PLANT_RELIABILITY_ENGINEER_DASHBOARD} />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
            key="reliability-engineer-dashboard"
            element={
              <Wrapper context={context} permissions={permissions}>
                <ReliabilityEngineerDashboard />
              </Wrapper>
            }
          />
        </>
      )}
    </>
  )
}
