import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import {ChecklistStepFormFields, HazardAssessmentFormFields} from '../../containers/TakeFive'
import {
  YesNoFormValue,
  type TakeFiveHazardAssessmentStepFormValues,
  type TakeFiveSafetyStepFormValues
} from '../../types'
import {
  getSafetyValidationSchema,
  getHazardAssessmentValidationSchema
} from '../../validators/take-five.validators'
import {type StepsConfig} from '../useStepperFormContext'
import {useUserContext} from '../useUserContext'

export const useTakeFiveStepsConfig = (): StepsConfig => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const user = useUserContext()

  return useMemo(
    () => [
      {
        key: 'safety',
        label: t(`${checklistsPrefix}.takeFive.label.checklist`),
        StepFormContent: ChecklistStepFormFields,
        validationSchema: getSafetyValidationSchema({t}),
        defaultValues: safetyDefaultValues
      },
      {
        key: 'hazardAssessment',
        label: t(`${checklistsPrefix}.takeFive.label.hazardAssessment`),
        StepFormContent: HazardAssessmentFormFields,
        validationSchema: getHazardAssessmentValidationSchema({t}),
        defaultValues: {...hazardAssessmentDefaultValues, preparedBy: user.name}
      }
    ],
    [t, checklistsPrefix, user]
  )
}

const safetyDefaultValues: TakeFiveSafetyStepFormValues = {
  peopleInformed: {value: YesNoFormValue.YES, comment: ''},
  rightTools: {value: YesNoFormValue.YES, comment: ''},
  fitForDuty: {value: YesNoFormValue.YES, comment: ''},
  riskRecognized: {value: YesNoFormValue.YES, comment: ''},
  reasonToWorkSafe: '',
  supervisor: null
}

const hazardAssessmentDefaultValues: TakeFiveHazardAssessmentStepFormValues = {
  preparedBy: null,
  workToBeDone: '',
  hazards: [],
  hazardsComment: '',
  risks: [],
  risksComment: '',
  eliminateControlHazard: ''
}
