import {LegendItem} from '@hconnect/common/components/runningTimes/LegendItem'
import {Box, Stack, useTheme} from '@mui/material'
import {type ChartOptions} from 'chart.js'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {DoughnutChart} from '../../performanceDashboard/components/charts/DoughnutChart'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {mapToWorkOrderSummaryChartDatasets} from '../mappers/work-orders.mappers'
import type {WorkOrderSummaryChartDataset, AggregatedWorkOrderSummaryDto} from '../types'

const chartOptions: ChartOptions<'doughnut'> = {
  cutout: '78%',
  events: [],
  responsive: true
}

type Props = {
  data?: AggregatedWorkOrderSummaryDto
}

export const WorkOrdersSummaryChart = ({data}: Props) => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()
  const theme = useTheme()

  const summaryInfoChartPlugin = useMemo(() => {
    const total = data?.totalWorkOrders ?? 0
    const closed = data?.closedWorkOrders ?? 0
    const overdue = data?.overdueWorkOrders ?? 0

    return {
      id: 'summary_info_chart_plugin',
      afterDatasetsDraw(chart) {
        const LABEL_LINE_HEIGHT = 16
        const VALUE_LINE_HEIGHT = 24
        const SPACING = 4

        const {ctx} = chart
        const xCenter = chart.getDatasetMeta(0).data[0].x
        const yCenter = chart.getDatasetMeta(0).data[0].y - LABEL_LINE_HEIGHT / 2

        const drawLabel = (label: string, y: number) => {
          ctx.font = `normal 400 12px/${LABEL_LINE_HEIGHT} Inter`
          ctx.textAlign = 'center'
          ctx.fillStyle = theme.palette.text.secondary
          ctx.textBaseLine = 'middle'
          ctx.fillText(label, xCenter, y)
        }
        const drawValue = (value: number, y: number, color?: string) => {
          ctx.font = `normal 500 16px/${VALUE_LINE_HEIGHT} Inter`
          ctx.textAlign = 'center'
          ctx.fillStyle = color ?? theme.palette.text.primary
          ctx.textBaseLine = 'middle'
          ctx.fillText(value.toString(), xCenter, y)
        }

        ctx.save()

        // Draw total work orders (top)
        const totalLabelY = yCenter - SPACING - VALUE_LINE_HEIGHT - LABEL_LINE_HEIGHT
        drawLabel(t(`${checklistsPrefix}.workOrderSummary.label.total`), totalLabelY)
        drawValue(total, totalLabelY + LABEL_LINE_HEIGHT)

        // Draw overdue work orders (middle)
        drawLabel(t(`${checklistsPrefix}.workOrderSummary.label.overdue`), yCenter)
        drawValue(
          overdue,
          yCenter + LABEL_LINE_HEIGHT,
          overdue ? theme.palette.error.main : theme.palette.text.primary
        )

        // Draw closed work orders (bottom)
        const closedLabelY = yCenter + LABEL_LINE_HEIGHT + VALUE_LINE_HEIGHT + SPACING
        drawLabel(t(`${checklistsPrefix}.workOrderSummary.label.closed`), closedLabelY)
        drawValue(closed, closedLabelY + LABEL_LINE_HEIGHT)

        ctx.restore()
      }
    }
  }, [theme, t, checklistsPrefix, data])

  if (!data) return null

  const chartDatasets = mapToWorkOrderSummaryChartDatasets(data)
  const sortedDatasets = chartDatasets.sort((a, b) => b.count - a.count)

  return (
    <Box sx={{px: 3, display: 'flex', justifyContent: 'center'}}>
      <Box display="flex" alignItems="center" gap={{xs: 2, md: 4, lg: 8}}>
        <Box sx={{width: 230, height: 230}}>
          <DoughnutChart
            data={sortedDatasets.map((summary) => summary.count)}
            backgroundList={sortedDatasets.map((summary) => summary.color)}
            pluginsList={[summaryInfoChartPlugin]}
            options={chartOptions}
          />
        </Box>
        <Box sx={{minWidth: 230}}>
          <WorkOrdersSummaryChartLegend data={chartDatasets} />
        </Box>
      </Box>
    </Box>
  )
}

type WorkOrdersSummaryChartLegendProps = {
  data: WorkOrderSummaryChartDataset[]
}

const WorkOrdersSummaryChartLegend = ({data}: WorkOrdersSummaryChartLegendProps) => {
  const {t} = useTranslation()
  const {checklistsPrefix} = useTranslationPrefix()

  return (
    <Stack spacing={1}>
      {data.map(({typeName, color, count}) => {
        const nameLabel = t(`${checklistsPrefix}.workOrderSummary.sapWorkOrderTypes.${typeName}`)

        return <LegendItem key={typeName} color={color} label={`${nameLabel} (${count})`} />
      })}
    </Stack>
  )
}
