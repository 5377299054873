import {PageTitle, customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Button, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

import {BackToLink} from './BackToLink'

type ChecklistPageHeaderProps = {
  title?: string
  backButtonProps?: {
    targetName: string
    target: string
    onNavigate?: (target: string) => void
  }
  afterTitle?: React.ReactNode
  actions?: React.ReactNode
  onSaveAsDraftClick?: () => void
}

export const ChecklistPageHeader = React.memo<ChecklistPageHeaderProps>(
  ({title, backButtonProps, onSaveAsDraftClick, afterTitle = null}) => {
    const {t} = useTranslation()
    const {checklistsPrefix} = useTranslationPrefix()

    return (
      <>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          justifyContent={'space-between'}
          flexWrap="wrap"
          mb={{xs: 2, sm: 3}}
          gap={1}
        >
          <Stack mr="auto">
            {backButtonProps && <BackToLink {...backButtonProps} />}
            <PageTitle
              mx={3}
              mb={!backButtonProps ? 2 : 0}
              sx={{display: 'inline-flex', alignItems: 'center'}}
              data-test-id="page-title"
            >
              {title}
            </PageTitle>
            {afterTitle}
          </Stack>
          {onSaveAsDraftClick && (
            <Button
              variant="outlined"
              sx={{
                borderColor: customThemeConstants().palette.mediumInputBorder,
                color: ({palette}) => palette.common.white
              }}
              onClick={onSaveAsDraftClick}
            >
              {t(`${checklistsPrefix}.button.saveAsDraft`)}
            </Button>
          )}
        </Stack>
      </>
    )
  }
)
