import {dateFormatter} from '@hconnect/uikit'
import {Column, DataTable} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {eventRowSx} from '../../myTasks/components/styles'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {ChecklistTemplate} from '../types'

type Props = {
  data?: ChecklistTemplate[]
  isLoading?: boolean
  emptyMessage?: string
  selectedRowId?: string
  onRowClick?: (item: ChecklistTemplate) => void
}

export const ChecklistTemplatesTable = ({
  data,
  isLoading,
  emptyMessage,
  selectedRowId,
  onRowClick
}: Props) => {
  const {checklistsPrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  const columns = useMemo(() => generateColumns({t, checklistsPrefix}), [t, checklistsPrefix])

  return (
    <DataTable<ChecklistTemplate>
      columns={columns}
      data={data}
      loading={isLoading}
      emptyMessage={emptyMessage}
      data-test-id={'checklist-templates-table'}
      onRowClick={(_, item) => onRowClick?.(item)}
      rowSx={({id}) => eventRowSx(selectedRowId === id)}
    />
  )
}

const generateColumns = ({
  t,
  checklistsPrefix
}: {
  t: TFunction
  checklistsPrefix: string
}): Column<ChecklistTemplate>[] => [
  {
    key: 'name',
    label: t(`${checklistsPrefix}.label.checklistName`)
  },
  {
    key: 'description',
    label: t(`${checklistsPrefix}.label.description`)
  },
  {
    key: 'type',
    label: t(`${checklistsPrefix}.label.type`),
    customTemplate: ({type}) => t(`${checklistsPrefix}.checklist.templateType.${type}`)
  },
  {
    key: 'category',
    label: t(`${checklistsPrefix}.label.category`),
    customTemplate: ({category}) => t(`${checklistsPrefix}.checklist.templateCategory.${category}`)
  },
  {
    key: 'createdAt',
    label: t(`${checklistsPrefix}.label.addedToTheSystem`),
    customTemplate: ({createdAt}) => dateFormatter(moment(createdAt))
  }
]
